import { keyframes } from "@emotion/core";

export const Keyframes = {
  FadeIn: keyframes({
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  }),
  RollIn: keyframes({
    "0%": {
      width: "0%",
    },
    "100%": {
      width: "100%",
    },
  }),
};

// const _EXAMPLE: Interpolation = {
//   animation: `${Keyframes.FadeIn} 0.2s linear`,
// };
