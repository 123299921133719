export enum Colors {
  White = "#ffffff",
  Black = "#1C1C1C",
  LightGrey = "#F3F5FB",
  BorderGrey = "#C7C7C7",
  MidGrey = "#767984",
  DarkGrey = "#3C3C3C",

  DarkBlueFooter = "#1A1A40",
  DarkBlueHeader = "#0C2D82",
  DarkBlue = "#1B3F9B",
  Blue = "#3263E2",
  LightBlue = "#56A7F2",

  Red = "#EB5757",
  Orange = "#FE6B18",
  Green = "#25B086",
  Aqua = "#57BCDD",
  Purple = "#9B51E0",
  DarkPurple = "#792EBE",
  Pink = "#EE498E",

  DarkModeDeepBlack = "#1F222F",
  DarkModeBlack = "#252937",
  DarkModeBackground = "#303442",
  DarkModeGrey = "#3B4154",
  DarkModeBorder = "#565b6b",
  DarkModeTextGrey = "#C4C8CA",
  DarkModeWhite = "#F3F5FB",
  DarkModeTextBlue = "#63B4FF",
}

export enum RainBow {
  Red = "#E51C22",
  Orange = "#FD5621",
  Amber = "#FF9C07",
  GrassGreen = "#4CB050",
  DeepGreen = "#029688",
  Teal = "#00A8BD",
  SkyBlue = "#2196F3",
  BlueBerry = "#3E50B4",
  Grape = "#663BB7",
  Plum = "#9B27B0",
  Pink = "#E91D64",
  Grey = "#5F7D8C",
}
