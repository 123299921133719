import React, { HTMLProps } from "react";
import { Interpolation } from "@emotion/core";
import { Link, GatsbyLinkProps } from "gatsby";
import {
  EventCategory,
  getEventAttributes,
  pushActionableClickEvent,
} from "../../../utils/analytics";
import styles from "./question-link.styles";

interface QuestionLinkProps<TState> extends GatsbyLinkProps<TState> {
  to: string;
  isHighlight?: boolean;
  state?: TState;
  eventCategory: EventCategory;
}

const QuestionLink: React.FC<QuestionLinkProps<any>> = ({
  to: url,
  isHighlight,
  state,
  eventCategory,
  children,
}) => {
  return (
    <Link
      to={url}
      state={state}
      css={[styles.root, isHighlight && styles.highlight]}
      {...getEventAttributes({
        category: eventCategory,
        action: "Click - Question link",
        label:
          typeof children === "string" ? children : children?.toString() || "",
      })}
    >
      {children}
    </Link>
  );
};

export default QuestionLink;

export const QuestionLinkSkeleton: React.FC = () => <div css={styles.root} />;

interface QuestionButtonProps extends HTMLProps<HTMLDivElement> {
  css?: Interpolation;
  isHighlight?: boolean;
  eventCategory: EventCategory;
}

export const QuestionButton: React.FC<QuestionButtonProps> = ({
  onClick,
  css,
  isHighlight,
  eventCategory,
  children,
  ...otherProps
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    pushActionableClickEvent({
      category: eventCategory,
      action: "Click - Question link",
      label:
        typeof children === "string" ? children : children?.toString() || "",
    });
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      css={[styles.root, isHighlight && styles.highlight, css]}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </div>
  );
};
