import { FontWeightProperty } from "csstype";

export const Fonts = {
  Family:
    "Roboto, 'Helvetica Neue', HelveticaNeue, Helvetica, Arial, sans-serif",
  LineHeight: {
    Hero: "34px",
    H1: "26px",
    H2: "24px",
    Default: "22px",
    Detail: "20px",
  },
  Size: {
    Hero: "28px",
    H1: "22px",
    H2: "18px",
    Default: "16px",
    Detail: "14px",
  },
  Weight: {
    Light: 300 as FontWeightProperty,
    Normal: 400 as FontWeightProperty,
    SemiBold: 500 as FontWeightProperty,
    Bold: 700 as FontWeightProperty,
  },
};
