import * as React from "react";
import { Link } from "gatsby";
import Container from "../../ui-library/container/container";
import Image from "../../ui-library/image/image";
import logo from "../../../images/main-bunny.svg";
import questionLogo from "../../../images/question-bunny-animated-25sec.svg";
import faceMaskLogo from "../../../images/face-mask-bunny-8sec.svg";
import AskInput from "../ask-input/ask-input";
import { getEventAttributes, EventCategory } from "../../../utils/analytics";
import { Topic } from "../../../types/topics";
import styles from "./header.styles";

export enum LogoBunny {
  Main = "Main",
  QuestionMark = "QuestionMark",
  FaceMask = "FaceMask",
}

const logoBunnyUrl = {
  [LogoBunny.Main]: logo,
  [LogoBunny.QuestionMark]: questionLogo,
  [LogoBunny.FaceMask]: faceMaskLogo,
};

interface HeaderProps {
  topic?: Topic;
  description?: string;
  headerUrl: string;
  logoBunny: LogoBunny;
  isTopicHomePage?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  topic,
  description,
  headerUrl,
  logoBunny,
  isTopicHomePage,
}) => {
  const displayTopic = topic || "Ask About";
  const displayDescription = description || "Get quick answers";

  return (
    <header css={styles.root}>
      <Container css={styles.container} fullWidthOnMobile={true}>
        <Link
          to="/"
          css={styles.logoContainer}
          {...getEventAttributes({
            category: EventCategory.Site_Header,
            action: "Click - Bunny",
            label: "Bunny",
          })}
        >
          <div css={styles.betaBadge}>BETA</div>
          <Image
            src={logoBunnyUrl[logoBunny]}
            alt="Logo"
            css={styles.logo}
            turnOffLazyLoading={true}
          />
        </Link>

        <div css={styles.chatBubble}>
          <Link
            to={headerUrl}
            css={styles.headingContainer}
            {...getEventAttributes({
              category: EventCategory.Site_Header,
              action: "Click - Topic name",
              label: displayTopic,
            })}
          >
            {isTopicHomePage ? (
              <h1 css={styles.pageHeading}>{displayTopic}</h1>
            ) : (
              <div css={styles.pageHeading}>{displayTopic}</div>
            )}
            <div css={styles.subHeading}>{displayDescription}</div>
          </Link>

          <AskInput topic={topic} />
        </div>
      </Container>
    </header>
  );
};

export default Header;
