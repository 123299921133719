import ApolloClient, { InMemoryCache } from "apollo-boost";
import fetch from "isomorphic-fetch";

interface INetworkLayerOptions {
  uri: string;
  token?: string;
}

export const createApolloClient = ({ uri, token }: INetworkLayerOptions) =>
  new ApolloClient({
    name: "Ask About site",
    uri,
    fetch,
    cache: new InMemoryCache(),
    request: (operation) => {
      operation.setContext({
        headers: token
          ? {
              authorization: `Bearer ${token}`,
            }
          : {},
      });
    },
  });
