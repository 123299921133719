import { Interpolation } from "@emotion/core";
import { lighten } from "polished";
import { Fonts } from "./design-tokens/fonts";
import { Colors } from "./design-tokens/colors";
import { grid } from "./design-tokens/grid";
import { Device, DarkTheme } from "./design-tokens/media-queries";
import { Types } from "./design-tokens/types";

/*
 * Base styles
 * ========================================================================== */

export const globalStyle: Interpolation = {
  "html, body, div, article, aside, details, footer, header, section, applet, iframe, embed, object, h1, h2, h3, h4, h5, h6, hgroup, blockquote, p, pre, span, a, abbr, acronym, address, big, cite, code, del, dfn, em, ins, kbd, q, ruby, s, samp, small, strike, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, label, legend, fieldset, form, table, caption, tbody, tfoot, thead, tr, th, td, img, figure, figcaption, menu, nav, output, summary, time, mark, canvas, audio, video": {
    margin: "0",
    padding: "0",
    border: "0",
    font: "inherit",
    verticalAlign: "baseline",
  },

  html: {
    fontFamily: `${Fonts.Family} !important`,
    boxSizing: "border-box",
    minHeight: "100vh",
    MozOsxFontSmoothing: "grayscale",
  },

  body: {
    fontFamily: Fonts.Family,
    color: Colors.Black,
    background: Colors.LightGrey,
    fontSize: "16px",
    lineHeight: 1.5,
    position: "relative",
    WebkitFontSmoothing: "antialiased",

    [DarkTheme]: {
      color: Colors.DarkModeWhite,
      background: Colors.DarkModeBlack,
    },
  },

  "article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section": {
    display: "block",
  },

  a: Types.Link,

  "blockquote, p": {
    quotes: "none",
  },

  "blockquote::before, blockquote::after, q::before, q::after": {
    content: `""`,
  },

  table: {
    borderCollapse: "collapse",
    borderSpacing: "0",
  },

  ".content": {
    h2: {
      fontSize: "20px",
      fontWeight: Fonts.Weight.Bold,
      lineHeight: "26px",
      marginTop: grid(3),
    },

    h3: {
      fontSize: "18px",
      fontWeight: Fonts.Weight.Bold,
      marginTop: grid(3),
    },

    "ol, ul": {
      listStyle: "none",

      ["ol, ul"]: {
        marginLeft: grid(2.5),
      },
    },

    ["p, ul, ol, img, h2, h3"]: {
      marginBottom: grid(2),
    },

    ["iframe"]: {
      marginBottom: grid(4),
    },

    ["iframe, img"]: {
      maxWidth: "100%",
    },

    ["ol"]: {
      counterReset: "section",
      listStyleType: "none",
    },

    ["li"]: {
      listStyleType: "none !important",
      marginLeft: 0,
      position: "relative",
      lineHeight: "24px",
      paddingLeft: grid(2.5),
      marginBottom: grid(1.5),

      [Device.DesktopTablet]: {
        paddingRight: grid(4),
      },

      "&::before": {
        position: "absolute",
        left: 0,
        display: "block",
        width: 10,
        height: 10,
      },
    },

    ["ul > li"]: {
      "&::before": {
        content: `''`,
        borderRadius: "50%",
        backgroundColor: Colors.LightBlue,
        top: 7,
      },
    },

    ["ol > li"]: {
      "&::before": {
        counterIncrement: "section",
        content: `counter(section)'.'`,
      },
    },
  },

  "*, *::before, *::after": {
    fontFamily: "inherit",
    boxSizing: "inherit",
  },

  "button, input, optgroup, select, textarea": {
    fontFamily: Fonts.Family,
  },

  input: {
    margin: "0",
  },

  "input::-ms-clear": {
    display: "none",
    width: "0",
    height: "0",
  },

  "input::-webkit-clear-button": {
    WebkitAppearance: "none",
    display: "none",
    width: "0",
    height: "0",
  },

  "input::-webkit-search-cancel-button": {
    display: "none",
    width: "0",
    height: "0",
  },

  "::-moz-selection, ::selection": {
    background: "#b3d4fc",
    textShadow: "none",
  },

  [Device.Desktop]: {
    [DarkTheme]: {
      "div::-webkit-scrollbar": {
        width: 8,
      },

      "div::-webkit-scrollbar-track": {
        backgroundColor: lighten(0.02, Colors.DarkModeBlack),
      },

      "div::-webkit-scrollbar-thumb": {
        backgroundColor: Colors.DarkModeBorder,
        borderRadius: "10px",
      },
    },
  },

  /*
   * A better looking default horizontal rule
   */

  hr: {
    display: "block",
    height: "1px",
    border: "0",
    borderTop: `1px solid ${Colors.BorderGrey}`,
    margin: "20px 0",
    padding: "0",

    [DarkTheme]: {
      borderTop: `1px solid ${Colors.DarkModeBorder}`,
    },
  },

  /*
   * Remove the gap between audio, canvas, iframes,
   * images, videos and the bottom of their containers:
   * https://github.com/h5bp/html5-boilerplate/issues/440
   */

  "audio, canvas, iframe, img, svg, video": {
    verticalAlign: "middle",
  },

  /*
   * Remove default fieldset styles.
   */

  fieldset: {
    border: "0",
    margin: "0",
    padding: "0",
  },

  /*
   * Allow only vertical resizing of textareas.
   */

  textarea: {
    resize: "vertical",
  },

  /*
   * Browser upgrade prompt
   * ========================================================================== */

  ".browserupgrade": {
    margin: "0.2em 0",
    background: "#ccc",
    color: "#000",
    padding: "0.2em 0",
  },

  "@media print": {
    ["*, *::before, *::after"]: {
      background: "transparent !important",
      color: "#000 !important",
      boxShadow: "none !important",
      textShadow: "none !important",
    },

    ["a, a:visited"]: {
      textDecoration: "underline",
    },

    ["a[href]::after"]: {
      content: " (' attr(href) ')",
    },

    ["abbr[title]::after"]: {
      content: " (' attr(title) ')",
    },

    [`a[href^='#']::after, a[href^='javascript:']::after`]: {
      content: `""`,
    },

    ["pre, blockquote"]: {
      border: "1px solid #999",
      pageBreakInside: "avoid",
    },

    ["thead"]: {
      display: "table-header-group",
    },

    ["tr, img"]: {
      pageBreakInside: "avoid",
    },

    ["img"]: {
      maxWidth: "100% !important",
    },

    ["p, h2, h3"]: {
      orphans: 3,
      widows: 3,
    },

    ["h2, h3"]: {
      pageBreakAfter: "avoid",
    },
  },
};

const content: Interpolation = {
  minHeight: "calc(100vh - 275px)",
};

export const styles = {
  content,
};
