import React, { useContext } from "react";
import { navigate } from "gatsby";
import Image from "../../ui-library/image/image";
import { AskAboutContext } from "../../../site-context/ask-about-context";
import {
  setAskInputFocused,
  setAskTerm,
} from "../../../site-context/store/ask-input";
import {
  pushActionableClickEvent,
  EventCategory,
} from "../../../utils/analytics";
import { useClickOutHooks } from "../../../hooks/use-click-out-hooks";
import { Topic } from "../../../types/topics";
import { mapTopicUrlSegment } from "../../../utils/map-topics";
import clearInputIcon from "./assets/clear-icon.svg";
import askCTAIcon from "./assets/ask-cta.svg";
import styles from "./ask-input.styles";
import IntentAutoSuggestBlockchain from "./intent-auto-suggest-blockchain";
import IntentAutoSuggestCovid from "./intent-auto-suggest-covid";

interface AskInputProps {
  topic?: Topic;
}

const AskInput: React.FC<AskInputProps> = ({ topic }) => {
  const {
    store: {
      isMobile,
      askInput: [{ term, isFocused }, dispatch],
    },
  } = useContext(AskAboutContext);

  const inputRef = React.useRef<HTMLDivElement>(null);

  const [termState, setTermState] = React.useState<string>(term);

  useClickOutHooks({
    containerRef: inputRef,
    callback: () => dispatch(setAskInputFocused(false)),
    isFocused,
  });

  const updateAskTerm = () => {
    dispatch(setAskTerm(termState));
  };

  const cancelAsk = () => {
    dispatch(setAskInputFocused(false));

    pushActionableClickEvent({
      category: EventCategory.Site_Ask,
      action: "Click - Cancel ask",
      label: "Cancel",
    });
  };

  const performAsk = () => {
    updateAskTerm();
    dispatch(setAskInputFocused(false));
    pushActionableClickEvent({
      category: EventCategory.Site_Ask,
      action: "Click - Ask",
      label: termState,
    });
    navigate(
      `/${mapTopicUrlSegment(
        topic || Topic.COVID19
      )}/ask/?ask=${encodeURIComponent(termState)}`,
      {
        state: { term: termState },
      }
    );
  };

  const placeholder = topic
    ? `Ask me a ${topic} question...`
    : "Ask me a COVID-19 question...";

  return (
    <>
      <div ref={inputRef} css={styles.root}>
        <form
          action=""
          onSubmit={(e) => {
            performAsk();
            e.preventDefault();
          }}
          css={[styles.inputContainer, isFocused && styles.inputFocus]}
        >
          <input
            type="search"
            css={styles.input}
            value={termState}
            placeholder={placeholder}
            onFocus={() => {
              window.scrollTo(0, 0);
              dispatch(setAskInputFocused(true));
            }}
            onChange={(e) => setTermState(e.target.value)}
          />
          <div
            css={[styles.askCTA, isFocused && styles.hiddenMobile]}
            onClick={performAsk}
          >
            Ask
            {!isMobile && (
              <Image src={askCTAIcon} alt="Ask" turnOffLazyLoading={true} />
            )}
          </div>
          {isFocused && (
            <div
              onClick={cancelAsk}
              css={[
                styles.cancelInputButton,
                !isFocused &&
                  termState.length === 0 &&
                  styles.cleanInputButtonHidden,
              ]}
            >
              <Image
                src={clearInputIcon}
                alt="Clear"
                turnOffLazyLoading={true}
              />
            </div>
          )}
          {isFocused && termState.trim().length > 1 && (
            <>
              {/* TODO: Home page */}
              {topic === Topic.Blockchain ? (
                <IntentAutoSuggestBlockchain
                  termState={termState}
                  onAsk={performAsk}
                />
              ) : (
                <IntentAutoSuggestCovid
                  termState={termState}
                  onAsk={performAsk}
                />
              )}
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default AskInput;
