import * as React from "react";

interface ClickOutHookInput {
  containerRef: React.RefObject<HTMLDivElement>;
  callback: () => void;
  isFocused: boolean;
}
export const useClickOutHooks = ({
  containerRef,
  callback,
  isFocused,
}: ClickOutHookInput) => {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        event.target &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        callback();
      }
    };
    if (isFocused && window && containerRef.current) {
      document.addEventListener("click", listener);
    }

    if (!isFocused && window) {
      document.removeEventListener("click", listener);
    }

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [isFocused]);
};
