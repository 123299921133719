import * as React from "react";
import { useCovidIntentListQuery } from "../../../hooks/static-queries/use-covid-intent-list-query";
import { useCountriesQuery } from "../../../hooks/static-queries/use-countries-query";
import { useCountriesWithResponseQuery } from "../../../hooks/static-queries/use-countries-with-response-query";
import { Topic } from "../../../types/topics";
import IntentAutoSuggest, { SearchItem } from "./intent-auto-suggest";

interface IntentAutoSuggestCovidProps {
  termState: string;
  onAsk: () => void;
}

const IntentAutoSuggestCovid: React.FC<IntentAutoSuggestCovidProps> = ({
  termState,
  onAsk,
}) => {
  const intents = useCovidIntentListQuery();
  const countries = useCountriesQuery();
  const countriesWithResponse = useCountriesWithResponseQuery();

  const intentList: SearchItem[] =
    intents?.map((i) => ({
      alias: i.alias,
      displayName: i.displayName,
    })) || [];

  const countryList: SearchItem[] = countries.map((c) => ({
    alias: `what-is-the-covid-19-situation-in-${c.alias}`,
    name: c.name,
    displayName: `What is the COVID-19 situation in ${c.displayName}?`,
  }));

  const countryWithResList: SearchItem[] = countriesWithResponse.map((c) => ({
    alias: `what-is-the-government-response-timeline-for-${c.alias}`,
    name: c.name,
    displayName: `What's the government response timeline for ${c.displayName}?`,
  }));

  const list = [...intentList, ...countryList, ...countryWithResList];

  return (
    <IntentAutoSuggest
      topic={Topic.COVID19}
      items={list}
      termState={termState}
      onAsk={onAsk}
    />
  );
};

export default IntentAutoSuggestCovid;
