import * as React from "react";
import "intersection-observer";
import { ApolloProvider } from "@apollo/react-hooks";
import { Global } from "@emotion/core";

import AskAboutProvider from "../site-context/ask-about-provider";
import { createApolloClient } from "../core/create-apollo-network-layer";
import { API_URL } from "../constants/urls";

import { Topic } from "../types/topics";
import Footer from "./site/footer/footer";
import { globalStyle, styles } from "./layout.styles";
import Header, { LogoBunny } from "./site/header/header";

interface LayoutProps {
  topic?: Topic;
  description?: string;
  term?: string;
  isTopicHomePage?: boolean;
  headerUrl: string;
  logoBunny: LogoBunny;
}

const askAboutApolloClient = createApolloClient({ uri: API_URL });

const Layout: React.FC<LayoutProps> = ({
  topic,
  description,
  term = "",
  isTopicHomePage,
  headerUrl,
  logoBunny,
  children,
}) => {
  return (
    <ApolloProvider client={askAboutApolloClient}>
      <AskAboutProvider term={term}>
        <Global styles={globalStyle} />
        <Header
          topic={topic}
          description={description}
          headerUrl={headerUrl}
          logoBunny={logoBunny}
          isTopicHomePage={isTopicHomePage}
        />
        <main css={styles.content}>{children}</main>
        <Footer />
      </AskAboutProvider>
    </ApolloProvider>
  );
};

export default Layout;

export const BasicLayout: React.FC = ({ children }) => (
  <ApolloProvider client={askAboutApolloClient}>
    <AskAboutProvider>
      <Global styles={globalStyle} />
      {children}
    </AskAboutProvider>
  </ApolloProvider>
);
