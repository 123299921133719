import { CountryItem } from "../../types/country";

export interface ReducerUserCountry {
  country?: CountryItem;
  benchmarkCountry?: CountryItem;
  historyCountry?: CountryItem;
  isFocused: boolean;
}

export const initialUserCountryState: ReducerUserCountry = {
  isFocused: false,
};

export enum UserCountryActionType {
  SetCountry = "user-country/SetCountry",
  SetBenchmarkCountry = "user-country/SetBenchmarkCountry",
  SetHistoryCountry = "user-country/SetHistoryCountry",
  SetFocused = "user-country/SetFocused",
  CleanCountry = "user-country/CleanCountry",
  CleanBenchmarkCountry = "user-country/CleanBenchmarkCountry",
}

export type UserCountryAction =
  | {
      type: UserCountryActionType.SetCountry;
      country?: CountryItem;
    }
  | {
      type: UserCountryActionType.SetBenchmarkCountry;
      benchmarkCountry?: CountryItem;
    }
  | {
      type: UserCountryActionType.SetHistoryCountry;
      historyCountry?: CountryItem;
    }
  | {
      type: UserCountryActionType.SetFocused;
      isFocused: boolean;
    }
  | {
      type: UserCountryActionType.CleanCountry;
    }
  | {
      type: UserCountryActionType.CleanBenchmarkCountry;
    };

export const userCountryReducer = (
  state: ReducerUserCountry,
  action: UserCountryAction
) => {
  switch (action.type) {
    case UserCountryActionType.SetCountry:
      return {
        ...state,
        country: action.country,
      };

    case UserCountryActionType.SetBenchmarkCountry:
      return {
        ...state,
        benchmarkCountry: action.benchmarkCountry,
      };

    case UserCountryActionType.SetHistoryCountry:
      return {
        ...state,
        historyCountry: action.historyCountry,
      };

    case UserCountryActionType.SetFocused:
      return {
        ...state,
        isFocused: action.isFocused,
      };

    case UserCountryActionType.CleanCountry:
      return {
        ...state,
        country: undefined,
        historyCountry: undefined,
      };

    case UserCountryActionType.CleanBenchmarkCountry:
      return {
        ...state,
        benchmarkCountry: undefined,
      };

    default:
      return state;
  }
};

export const setHistoryUserCountry = (): UserCountryAction => {
  let historyCountry: CountryItem | undefined = undefined;

  if (window) {
    const historyCountryData = window.localStorage.getItem("userCountry");
    if (historyCountryData) {
      historyCountry = JSON.parse(historyCountryData) as CountryItem;
    }
  }

  return {
    type: UserCountryActionType.SetHistoryCountry,
    historyCountry,
  };
};

export const setSessionUserCountry = (): UserCountryAction => {
  let sessionCountry: CountryItem | undefined = undefined;

  if (window) {
    const sessionCountryData = window.sessionStorage.getItem("userCountry");
    if (sessionCountryData) {
      sessionCountry = JSON.parse(sessionCountryData) as CountryItem;
    }
  }
  return {
    type: UserCountryActionType.SetCountry,
    country: sessionCountry,
  };
};

export const setUserCountry = (country: CountryItem): UserCountryAction => {
  if (window) {
    window.localStorage.setItem("userCountry", JSON.stringify(country));
    window.sessionStorage.setItem("userCountry", JSON.stringify(country));
  }

  return {
    type: UserCountryActionType.SetCountry,
    country,
  };
};

export const setHistoryUserBenchmarkCountry = (): UserCountryAction => {
  let historyCountry: CountryItem | undefined = undefined;

  if (window) {
    const historyCountryData = window.localStorage.getItem(
      "userBenchmarkCountry"
    );
    if (historyCountryData) {
      historyCountry = JSON.parse(historyCountryData) as CountryItem;
    }
  }
  return {
    type: UserCountryActionType.SetBenchmarkCountry,
    benchmarkCountry: historyCountry,
  };
};

export const setUserBenchmarkCountry = (
  benchmarkCountry: CountryItem
): UserCountryAction => {
  if (window) {
    window.localStorage.setItem(
      "userBenchmarkCountry",
      JSON.stringify(benchmarkCountry)
    );
  }

  return {
    type: UserCountryActionType.SetBenchmarkCountry,
    benchmarkCountry,
  };
};

export const setCountryInputFocused = (
  isFocused: boolean
): UserCountryAction => ({
  type: UserCountryActionType.SetFocused,
  isFocused,
});

export const cleanUserCountry = (): UserCountryAction => {
  if (window) {
    window.localStorage.removeItem("userCountry");
    window.sessionStorage.removeItem("userCountry");
  }

  return {
    type: UserCountryActionType.CleanCountry,
  };
};

export const cleanUserBenchmarkCountry = (): UserCountryAction => {
  if (window) {
    window.localStorage.removeItem("userBenchmarkCountry");
  }

  return {
    type: UserCountryActionType.CleanBenchmarkCountry,
  };
};
