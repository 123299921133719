import { useStaticQuery, graphql } from "gatsby";
import { BlockchainIntentListQuery } from "../../../graphql-types";

export const useBlockchainIntentListQuery = () => {
  const data = useStaticQuery<BlockchainIntentListQuery>(
    graphql`
      query BlockchainIntentList {
        askAboutQuery {
          blockchainAllIntents {
            ...IntentBaseFrament
          }
        }
      }
    `
  );

  const intents = data.askAboutQuery.blockchainAllIntents;

  return intents;
};
