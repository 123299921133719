import * as React from "react";
import { Link } from "gatsby";
import { animateScroll } from "react-scroll";
import Container from "../../ui-library/container/container";
import logo from "../../../images/answer-bunny-black-nose.svg";
import Image from "../../ui-library/image/image";
import { EventCategory, getEventAttributes } from "../../../utils/analytics";
import styles from "./footer.styles";

const Footer: React.FC = () => {
  return (
    <footer css={styles.root}>
      <Container css={styles.container}>
        <div css={styles.brandContainer}>
          <div
            css={styles.logoContainer}
            onClick={() => animateScroll.scrollToTop()}
            {...getEventAttributes({
              category: EventCategory.Site_Footer,
              action: "Click - Footer bunny",
              label: "Footer bunny",
            })}
          >
            <Image src={logo} alt="Logo" css={styles.logo} />
          </div>
          <div css={styles.slogan}>
            Get quick answers around topics you care about
          </div>
        </div>

        <hr css={styles.separator} />

        <div css={styles.linksContainer}>
          <Link
            to="/terms-of-use/"
            css={styles.link}
            {...getEventAttributes({
              category: EventCategory.Site_Footer,
              action: "Click - Terms of Use",
              label: "Terms of Use",
            })}
          >
            Terms of Use
          </Link>
          <Link
            to="/privacy-policy/"
            css={styles.link}
            {...getEventAttributes({
              category: EventCategory.Site_Footer,
              action: "Click - Privacy Policy",
              label: "Privacy Policy",
            })}
          >
            Privacy Policy
          </Link>
          <Link
            to="/disclaimer/"
            css={styles.link}
            {...getEventAttributes({
              category: EventCategory.Site_Footer,
              action: "Click - Disclaimer",
              label: "Disclaimer",
            })}
          >
            Disclaimer
          </Link>
          <div css={styles.tradeMark}>© 2020 Ask About</div>
        </div>

        <div css={styles.tradeMarkMobile}>© 2020 Ask About</div>
      </Container>
    </footer>
  );
};

export default Footer;
