import * as React from "react";
import { throttle } from "throttle-debounce";
import { askInputReducer, initialAskInputState } from "./store/ask-input";
import { AskAboutContext } from "./ask-about-context";
import {
  initialUserCountryState,
  userCountryReducer,
} from "./store/user-country";

interface AskAboutProviderProps {
  term?: string;
}

const AskAboutProvider: React.FC<AskAboutProviderProps> = ({
  term,
  children,
}) => {
  const askInput = React.useReducer(askInputReducer, {
    ...initialAskInputState,
    term: term || "",
  });

  const userCountry = React.useReducer(
    userCountryReducer,
    initialUserCountryState
  );

  const [isMobile, setMobile] = React.useState<boolean>(true);
  const [isDarkmode, setDarkmode] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (window) {
      const checkDeviceSize = () => {
        const windowSize = window.innerWidth;
        if (windowSize < 767) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      };
      checkDeviceSize();
      window.addEventListener("resize", throttle(500, checkDeviceSize));

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setDarkmode(true);
      }
    }
  }, []);

  const store = {
    isMobile,
    isDarkmode,
    askInput,
    userCountry,
  };

  return (
    <AskAboutContext.Provider value={{ store }}>
      {children}
    </AskAboutContext.Provider>
  );
};

export default AskAboutProvider;
