import { Interpolation } from "@emotion/core";
import { lighten } from "polished";
import { Colors } from "../../design-tokens/colors";
import { Border } from "../../design-tokens/borders";
import { grid } from "../../design-tokens/grid";
import { Fonts } from "../../design-tokens/fonts";
import { Keyframes } from "../../design-tokens/animation";
import { DarkTheme } from "../../design-tokens/media-queries";
import { BoxShadow } from "../../design-tokens/box-shadow";

const root: Interpolation = {
  display: "inline-flex",
  alignItems: "center",
  minHeight: grid(5.5),
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.Blue,
  backgroundColor: Colors.White,
  padding: grid(1.5),
  boxShadow: BoxShadow.Default,
  borderRadius: Border.Radius,
  transition: "all 0.2s ease",
  animation: `${Keyframes.FadeIn} 0.3s linear`,
  cursor: "pointer",

  [DarkTheme]: {
    color: Colors.DarkModeTextBlue,
    backgroundColor: Colors.DarkModeBackground,
  },

  "&:hover": {
    textDecoration: "none",
    transform: "translateY(-3px)",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)",
  },
};

const highlight: Interpolation = {
  backgroundColor: Colors.LightGrey,

  [DarkTheme]: {
    backgroundColor: lighten(0.05, Colors.DarkModeBackground),
  },
};

const styles = {
  root,
  highlight,
};

export default styles;
