import { Interpolation } from "@emotion/core";
import { Fonts } from "./fonts";
import { Colors } from "./colors";
import { DarkTheme } from "./media-queries";

const Hero: Interpolation = {
  fontSize: Fonts.Size.Hero,
  lineHeight: Fonts.LineHeight.Hero,
};

const H1: Interpolation = {
  fontSize: Fonts.Size.H1,
  lineHeight: Fonts.LineHeight.H1,
};

const H2: Interpolation = {
  fontSize: Fonts.Size.H2,
  lineHeight: Fonts.LineHeight.H2,
};

const Default: Interpolation = {
  fontSize: Fonts.Size.Default,
  lineHeight: Fonts.LineHeight.Default,
};

const Detail: Interpolation = {
  fontSize: Fonts.Size.Detail,
  lineHeight: Fonts.LineHeight.Detail,
};

const Link: Interpolation = {
  color: Colors.Blue,
  textDecoration: "none",

  [DarkTheme]: {
    color: Colors.DarkModeTextBlue,
  },

  "&:hover": {
    textDecoration: "underline",
  },
};

export const Types = {
  Hero,
  H1,
  H2,
  Default,
  Detail,
  Link,
};
