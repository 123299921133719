import { Interpolation } from "@emotion/core";
import { Colors } from "../../design-tokens/colors";
import { DarkTheme, Device } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import { Border } from "../../design-tokens/borders";

const root: Interpolation = {
  width: "100%",
  backgroundColor: Colors.White,
  padding: grid(2),
  overflowY: "auto",
  zIndex: 100,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },

  [Device.DesktopTablet]: {
    position: "absolute",
    top: grid(7),
    left: 0,
    borderRadius: Border.Radius,
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)",
  },

  [Device.Mobile]: {
    position: "fixed",
    top: grid(16),
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const styles = {
  root,
};

export default styles;
