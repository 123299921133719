import { Topic } from "../types/topics";
import { AskAboutTopics } from "../apollo-types/globalTypes";

export const mapTopic = (topic: Topic): AskAboutTopics => {
  switch (topic) {
    case Topic.COVID19:
      return AskAboutTopics.COVID19;

    case Topic.Blockchain:
      return AskAboutTopics.BLOCKCHAIN;
  }
};

export const mapTopicUrlSegment = (topic: Topic): "covid-19" | "blockchain" => {
  switch (topic) {
    case Topic.COVID19:
      return "covid-19";

    case Topic.Blockchain:
      return "blockchain";
  }
};
