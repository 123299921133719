/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ANSWER_TYPE {
  image = "image",
  text = "text",
  video = "video",
}

export enum AskAboutTopics {
  BLOCKCHAIN = "BLOCKCHAIN",
  COVID19 = "COVID19",
  FIVE_G = "FIVE_G",
}

export interface DetectDFIntentInput {
  text?: string | null;
}

export interface FBIntentByTopicInput {
  topic: AskAboutTopics;
  data: FBIntentInput;
}

export interface FBIntentInput {
  alias: string;
  intent_name: string;
  display_name: string;
  image_url?: string | null;
  training_phrases: string[];
  messages: string[];
  rich_answers: RichAnswerInput[];
  display_order?: number | null;
  is_featured: boolean;
  is_disabled: boolean;
  suggested_intent: SuggestedIntentInput;
  category?: IntentCategoryInput | null;
}

export interface IntentCategoryInput {
  name: string;
  alias: string;
}

export interface RichAnswerInput {
  type: ANSWER_TYPE;
  title?: string | null;
  content?: string[] | null;
  image_url?: string | null;
  youtube_id?: string | null;
  source?: SourceDataInput | null;
  sources?: SourceDataInput[] | null;
  tip?: TipInput | null;
}

export interface SourceDataInput {
  title: string;
  url: string;
}

export interface SubmitFeedbackByTopicInput {
  topic: AskAboutTopics;
  data: SubmitFeedbackInput;
}

export interface SubmitFeedbackInput {
  intentName: string;
  feedback?: string | null;
  message?: string | null;
}

export interface SuggestedIntentInput {
  alias: string;
  display_name: string;
}

export interface TipInput {
  title: string;
  description: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
