import * as React from "react";
import { Topic } from "../../../types/topics";
import { useBlockchainIntentListQuery } from "../../../hooks/static-queries/use-blockchain-intent-list-query";
import IntentAutoSuggest, { SearchItem } from "./intent-auto-suggest";

interface IntentAutoSuggestBlockchainProps {
  termState: string;
  onAsk: () => void;
}

const IntentAutoSuggestBlockchain: React.FC<IntentAutoSuggestBlockchainProps> = ({
  termState,
  onAsk,
}) => {
  const intents = useBlockchainIntentListQuery();

  const intentList: SearchItem[] =
    intents?.map((i) => ({
      alias: i.alias,
      displayName: i.displayName,
    })) || [];

  return (
    <IntentAutoSuggest
      topic={Topic.Blockchain}
      items={intentList}
      termState={termState}
      onAsk={onAsk}
    />
  );
};

export default IntentAutoSuggestBlockchain;
