import { useStaticQuery, graphql } from "gatsby";
import { CountryListQuery } from "../../../graphql-types";

export const useCountriesQuery = () => {
  const data = useStaticQuery<CountryListQuery>(
    graphql`
      query CountryList {
        askAboutQuery {
          countryList {
            countries {
              isoCode
              name
              displayName
              alias
              population
              totalCases
            }
          }
        }
      }
    `
  );

  const countries = data.askAboutQuery.countryList?.countries || [];

  return countries;
};
