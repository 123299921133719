export const addQuestionMarkToTerm = (term: string) => {
  if (term.trim().endsWith("?")) {
    return term;
  } else {
    return `${term.trim()}?`;
  }
};

export const addQuestionMarkElipsisToTerm = (term: string) => {
  if (term.trim().endsWith("?")) {
    return `${term.trim().slice(0, term.length - 1)}...?`;
  } else {
    return `${term.trim()}...?`;
  }
};
