export interface ReducerAskInput {
  term: string;
  isFocused: boolean;
}

export const initialAskInputState: ReducerAskInput = {
  term: "",
  isFocused: false,
};

export enum AskInputActionType {
  SetTerm = "ask-input/SetTerm",
  SetFocused = "ask-input/SetFocused",
}

export type AskInputAction =
  | {
      type: AskInputActionType.SetTerm;
      term: string;
    }
  | {
      type: AskInputActionType.SetFocused;
      isFocused: boolean;
    };

export const askInputReducer = (
  state: ReducerAskInput,
  action: AskInputAction
) => {
  switch (action.type) {
    case AskInputActionType.SetTerm:
      return {
        ...state,
        term: action.term,
      };

    case AskInputActionType.SetFocused:
      return {
        ...state,
        isFocused: action.isFocused,
      };

    default:
      return state;
  }
};

export const setAskTerm = (term: string): AskInputAction => ({
  type: AskInputActionType.SetTerm,
  term,
});

export const setAskInputFocused = (isFocused: boolean): AskInputAction => ({
  type: AskInputActionType.SetFocused,
  isFocused,
});
