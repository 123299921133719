import { transparentize } from "polished";
import { Interpolation } from "@emotion/core";
import { Fonts } from "../../design-tokens/fonts";
import { grid } from "../../design-tokens/grid";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Colors } from "../../design-tokens/colors";

const root: Interpolation = {
  backgroundColor: Colors.DarkGrey,
  paddingTop: grid(3),
  paddingBottom: grid(3),

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBackground,
  },

  [Device.DesktopTablet]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

const container: Interpolation = {
  display: "flex",
  alignItems: "center",

  [Device.DesktopTablet]: {
    justifyContent: "space-between",
  },

  [Device.Mobile]: {
    flexDirection: "column",
  },
};

const brandContainer: Interpolation = {
  display: "flex",
  alignItems: "center",

  [Device.Mobile]: {
    flexDirection: "column",
  },
};

const logoContainer: Interpolation = {
  cursor: "pointer",

  [Device.Mobile]: {
    marginBottom: grid(2),
  },
  [Device.DesktopTablet]: {
    marginRight: grid(1),
  },
};

const logo: Interpolation = {
  width: grid(12),

  [DarkTheme]: {
    opacity: "0.95",
  },

  [Device.DesktopTablet]: {
    width: grid(15),
  },
};

const slogan: Interpolation = {
  width: grid(20),
  color: Colors.White,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  [Device.Mobile]: {
    textAlign: "center",
  },
};

const separator: Interpolation = {
  width: "100%",
  borderTop: `1px solid ${transparentize(0.9, Colors.White)}`,
  marginTop: grid(3),
  marginBottom: grid(3),

  [Device.DesktopTablet]: {
    display: "none",
  },
};

const linksContainer: Interpolation = {
  [Device.DesktopTablet]: {
    display: "flex",
  },

  [Device.Mobile]: {
    marginBottom: grid(3),
  },
};

const link: Interpolation = {
  color: Colors.White,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  "&::after": {
    content: `"|"`,
    color: Colors.MidGrey,
    marginLeft: grid(1.5),
    marginRight: grid(1.5),

    [Device.MobilePortXs]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },

  [Device.Mobile]: {
    fontSize: "14px",

    "&:last-of-type::after": {
      display: "none",
    },
  },
};

const tradeMark: Interpolation = {
  color: Colors.White,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  [Device.Mobile]: {
    display: "none",
  },
};

const tradeMarkMobile: Interpolation = {
  fontSize: "14px",
  color: Colors.White,
  fontWeight: Fonts.Weight.Bold,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  [Device.DesktopTablet]: {
    display: "none",
  },
};

const sitemapLink: Interpolation = {
  color: "inherit",
  cursor: "inherit",

  ":hover": {
    textDecoration: "none",
  },
};

const styles = {
  root,
  container,
  brandContainer,
  logoContainer,
  logo,
  slogan,
  separator,
  linksContainer,
  link,
  tradeMark,
  tradeMarkMobile,
  sitemapLink,
};

export default styles;
