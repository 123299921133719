import { useStaticQuery, graphql } from "gatsby";
import { CovidIntentListQuery } from "../../../graphql-types";

export const useCovidIntentListQuery = () => {
  const data = useStaticQuery<CovidIntentListQuery>(
    graphql`
      query CovidIntentList {
        askAboutQuery {
          covidAllIntents {
            ...IntentBaseFrament
          }
        }
      }
    `
  );

  const intents = data.askAboutQuery.covidAllIntents;

  return intents;
};
