import { Interpolation } from "@emotion/core";
import { transparentize } from "polished";
import { grid } from "../../design-tokens/grid";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Fonts } from "../../design-tokens/fonts";
import { Colors } from "../../design-tokens/colors";

const root: Interpolation = {
  position: "relative",
  width: "100%",
  backgroundColor: Colors.Blue,

  "&::before": {
    content: `''`,
    display: "block",
    position: "absolute",
    right: 0,
    width: "50%",
    height: "100%",
    backgroundColor: Colors.DarkBlue,
  },
};

const container: Interpolation = {
  height: "100%",
};

const logoContainer: Interpolation = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
  zIndex: 1,

  [Device.Desktop]: {
    width: 180,
    top: -12,
    right: -35,
    bottom: 0,
    left: "auto",
  },

  [Device.Tablet]: {
    width: 160,
    top: -12,
    right: 0,
    bottom: 0,
    left: "auto",
  },

  [Device.Mobile]: {
    width: 115,
    top: -15,
    right: 15,
    paddingLeft: grid(1),
  },

  "&:hover": {
    textDecoration: "none",
  },
};

const betaBadge: Interpolation = {
  position: "absolute",
  left: 0,
  fontSize: "10px",
  lineHeight: "17px",
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: transparentize(0.2, Colors.Pink),
  borderRadius: grid(2),
  padding: `0 ${grid(0.5)}`,

  [Device.DesktopTablet]: {
    top: 32,
  },

  [Device.Mobile]: {
    top: 25,
  },
};

const logo: Interpolation = {
  width: 115,
  height: 101,

  [DarkTheme]: {
    opacity: "0.95",
  },

  [Device.DesktopTablet]: {
    width: 180,
    height: "auto",
  },
};

const chatBubble: Interpolation = {
  position: "relative",
  marginRight: grid(14),
  height: "100%",
  minHeight: 130,
  backgroundColor: Colors.Blue,
  padding: `${grid(2)} ${grid(2)} ${grid(2.5)}`,

  "&::before": {
    content: `''`,
    display: "block",
    position: "absolute",
    right: -18,
    top: grid(3),
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "15px 0 15px 18px",
    borderColor: `transparent transparent transparent ${Colors.Blue}`,

    [Device.DesktopTablet]: {
      top: `calc(50% - 15px)`,
    },
  },

  [Device.DesktopTablet]: {
    display: "flex",
    alignItems: "center",
    marginRight: grid(16),
    padding: `${grid(4)} ${grid(2.5)} ${grid(4)} 0`,
  },
};

const headingContainer: Interpolation = {
  display: "block",
  marginRight: grid(2),

  [Device.Mobile]: {
    marginBottom: grid(1.5),
  },

  "&:hover": {
    textDecoration: "none",
  },
};

const pageHeading: Interpolation = {
  color: Colors.White,
  fontSize: "28px",
  lineHeight: "30px",
  fontWeight: Fonts.Weight.Bold,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  [Device.MobilePortXs]: {
    fontSize: "24px",
  },
};

const subHeading: Interpolation = {
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.White,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },
};

const styles = {
  root,
  container,
  logoContainer,
  betaBadge,
  logo,
  chatBubble,
  headingContainer,
  pageHeading,
  subHeading,
};

export default styles;
