import * as React from "react";
import Fuse from "fuse.js";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import QuestionLink, {
  QuestionButton,
} from "../../ui-library/question-link/question-link";
import { EventCategory } from "../../../utils/analytics";
import { addQuestionMarkElipsisToTerm } from "../../../utils/format-term";
import Row from "../../ui-library/column-system/row";
import Column from "../../ui-library/column-system/column";
import { Topic } from "../../../types/topics";
import { mapTopicUrlSegment } from "../../../utils/map-topics";
import styles from "./intent-auto-suggest.styles";

const options = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.6,
  keys: [
    {
      name: "name",
      weight: 0.3,
    },
    {
      name: "displayName",
      weight: 0.7,
    },
  ],
};

export interface SearchItem {
  alias: string;
  name?: string;
  displayName: string;
}

interface IntentAutoSuggestProps {
  topic: Topic;
  termState: string;
  onAsk: () => void;
  items: SearchItem[];
}

const IntentAutoSuggest: React.FC<IntentAutoSuggestProps> = ({
  topic,
  termState,
  onAsk,
  items,
}) => {
  const suggestRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (window) {
      if (window.innerWidth < 767) {
        if (suggestRef.current) {
          disableBodyScroll(suggestRef.current);
        }
      }
    }
    return () => {
      if (suggestRef.current) {
        enableBodyScroll(suggestRef.current);
      }
      clearAllBodyScrollLocks();
    };
  }, []);

  const fuse = new Fuse(items, options);
  const matchingIntents = fuse
    .search(termState)
    .map((c) => c.item)
    .slice(0, 3);

  return (
    <div ref={suggestRef} css={styles.root}>
      <Row>
        <Column spanLg={12} spanMd={12} spanSm={12}>
          <QuestionButton
            onClick={(e) => {
              e.preventDefault();
              onAsk();
            }}
            isHighlight={true}
            eventCategory={EventCategory.Site_AutoSuggest}
          >
            Ask: {addQuestionMarkElipsisToTerm(termState)}
          </QuestionButton>
        </Column>

        {matchingIntents.map((i, index) => (
          <Column
            key={i.alias}
            spanLg={12}
            spanMd={12}
            spanSm={12}
            isNoMarginBottom={index === matchingIntents.length - 1}
          >
            <QuestionLink
              to={`/${mapTopicUrlSegment(topic)}/ask/${i.alias}/`}
              isHighlight={true}
              eventCategory={EventCategory.Site_AutoSuggest}
            >
              {i.displayName}
            </QuestionLink>
          </Column>
        ))}
      </Row>
    </div>
  );
};

export default IntentAutoSuggest;
