import { useStaticQuery, graphql } from "gatsby";
import { CountryListWithResponseQuery } from "../../../graphql-types";

export const useCountriesWithResponseQuery = () => {
  const data = useStaticQuery<CountryListWithResponseQuery>(
    graphql`
      query CountryListWithResponse {
        askAboutQuery {
          countryListWithResponse {
            countries {
              name
              displayName
              alias
            }
          }
        }
      }
    `
  );

  const countries = data.askAboutQuery.countryListWithResponse?.countries || [];

  return countries;
};
