export enum EventCategory {
  Site_Header = "Site - Header",
  Site_Ask = "Site - Ask",
  Site_Footer = "Site - Footer",
  Site_Navigate = "Site - Navigate",
  Site_AutoSuggest = "Site - Auto suggest",
  Section_MostAsked = "Section - Most asked questions",
  Section_RandomIntents = "Section - Random intent suggests",
  Section_FixedSuggestedIntents = "Section - Fixed set intent suggests",
  Section_TextAnswer = "Section - Text answer",
  Section_ImageAnswer = "Section - Image answer",
  Section_VideoAnswer = "Section - Video answer",
  Section_Feedback = "Section - Feedback",
  Section_GrowthFactor = "Section - Growth factor",
  Section_Timeline = "Section - Timeline",
  Section_SuggestedAsk = "Section - Suggested ask",
  Section_Categories = "Section - COVID-19 categories",

  InsightsPage = "Insights page",
  COVID_HomePage = "COVID-19 - Home",

  CountryPage = "Country page",
  CountryGovResponseTimelinePage = "Country government reponse timeline page",
  CountryGovResponseVsCases = "Country government reponse vs cases",
  CategoryPage = "COVID-19 category page",
  CountryDoingWellNNotWell = "COVID-19 country doing well and not well",
  USStatesControlSnapshots = "COVID-19 US states control snapshots",
  CountryControlVsTestsSnapshots = "COVID-19 country control with tests snapshots",
  CountriesInfectionRates = "COVID-19 countries infection rates",
  USStatesInfectionRates = "COVID-19 US states infection rates",
  CountryControlCompareTable = "COVID-19 country control compare table",
  CovidResourcesPage = "COVID-19 resources",
  CovidHowToWin = "COVID-19 how to win",
  MaskStopsTransmission = "Mask stops transmission infographic",

  GlobalSnapshot = "Global snapshot",
  GlobalSituation = "Global situation",
  IntentShare = "Intent share",
  AnswerFlag = "Answer flag",
  Sitemap = "Sitemap",
  HomeBanner = "Home banner",
  CollectQAndA = "Collect Q&A",
  Internal = "Internal",
}

export interface EventAttributes {
  category: EventCategory;
  action: string;
  label: string;
  value?: string;
}

interface EventData extends EventAttributes {
  event: "ActionableClick" | "Impression";
}

export const getEventAttributes = ({
  category,
  action,
  label,
  value,
}: EventAttributes) => {
  const eventAttrs: { [key: string]: string } = {
    "data-event-category": category,
    "data-event-action": action,
    "data-event-label": label,
  };

  if (value) {
    eventAttrs["data-event-value"] = value;
  }

  return eventAttrs;
};

const pushEventDataLayer = ({
  event,
  category,
  action,
  label,
  value,
}: EventData) => {
  const eventDataLayer: { [key: string]: string } = {
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  };

  if (value) {
    eventDataLayer.eventValue = value;
  }

  // @ts-ignore dataLayer
  if (!!window && !!dataLayer) {
    // @ts-ignore dataLayer
    dataLayer.push(eventDataLayer);
  }
};

export const pushActionableClickEvent = (attrs: EventAttributes) => {
  pushEventDataLayer({
    event: "ActionableClick",
    ...attrs,
  });
};

export const pushInpressionEvent = (attrs: EventAttributes) => {
  pushEventDataLayer({
    event: "Impression",
    ...attrs,
  });
};
