import React, { ReducerState, ReducerAction, Dispatch, Reducer } from "react";
import {
  initialAskInputState,
  ReducerAskInput,
  AskInputAction,
} from "./store/ask-input";
import {
  ReducerUserCountry,
  UserCountryAction,
  initialUserCountryState,
} from "./store/user-country";

type AskAboutReducer<State, Action> = [
  ReducerState<Reducer<State, Action>>,
  Dispatch<ReducerAction<Reducer<State, Action>>>
];

export interface AskAboutContext {
  store: {
    isMobile: boolean;
    isDarkmode: boolean;
    askInput: AskAboutReducer<ReducerAskInput, AskInputAction>;
    userCountry: AskAboutReducer<ReducerUserCountry, UserCountryAction>;
  };
}

const defaultContextValue: AskAboutContext = {
  store: {
    isMobile: false,
    isDarkmode: false,
    askInput: [initialAskInputState, () => {}],
    userCountry: [initialUserCountryState, () => {}],
  },
};

export const AskAboutContext = React.createContext<AskAboutContext>(
  defaultContextValue
);
